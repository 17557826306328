// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const MUTATION = gql`
  mutation Login ($input: LoginInput) {
    login(input: $input ) {
      token
      refresh_token
      expires_in
    }
  }
`;

export default MUTATION;
